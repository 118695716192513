import React from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";

import OpenInNewSharpIcon from "@material-ui/icons/OpenInNewSharp";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

import PageBanner from "../../assets/images/camping_banner.png";

import "./styles.grid.scss";

const useStyles = makeStyles((theme) => ({
  bsah4: {
    fontFamily: "Marcellus",
    color: "#003f87",
  },
  subheader: {
    fontFamily: "Marcellus",
    color: "#CE1126",
  },
  subheaderSmall: {
    fontFamily: "Marcellus",
    fontSize: "21px",
    color: "#CE1126",
  },
  hrmargin: {
    marginTop: "0px",
  },
  reservations: {
    padding: "10px",
    backgroundColor: "#fff7f0",
  },
  icons: {
    marginBottom: "-2px",
  },
  banner: {
    width: "100%",
  },
  reservationBody: {
    paddingBottom: "10px",
  },
  downloadnowitem: {
    marginLeft: "-20px",
    listStyleType: "none",
  },
  downloadsectionicons: {
    marginBottom: "-5px",
    marginRight: "10px",
  },
  ammenitiesContainer: {
    padding: "10px",
    marginTop: "10px",
  },
}));

export default function CampingPage() {
  const classes = useStyles();

  const downloads = [
    {
      text: "Camping Acknowledgement Form",
      link: "downloads/pdf/ECC_Camping_Acknowledgement_form.pdf",
      iconType: "PictureAsPdfIcon",
    },
    {
      text: "Unit Activity Rental Form-COVID 19",
      link: "downloads/pdf/ECC_Unit_Activity_Rental_Form-COVID_19_B.pdf",
      iconType: "PictureAsPdfIcon",
    },
  ];

  return (
    <div className="grid-camping-container">
      <div className="grid-camping-header-image">
        <img className={classes.banner} src={PageBanner} alt="" />
      </div>
      <Paper
        elevation={1}
        className={[classes.reservations, "grid-camping-reservations"].join(" ")}
      >
        <Typography variant="h5" className={classes.subheader}>
          Make Reservations:
        </Typography>
        <Typography variant="body1" className={classes.reservationBody}>
          Packs and Troops don't have to wait for District planned events to use Camp Sam Hatcher,
          or other Council properties. You just need to submit a Properties Use Permit to East
          Carolina Council.
        </Typography>
        <Typography variant="body1" className={classes.subheader}>
          Downloads:
        </Typography>
        <ul>
          {downloads.map((item, index) => (
            <li className={classes.downloadnowitem} key={index}>
              <Link
                variant="body2"
                target="_blank"
                rel="noopener"
                href={item.link}
                title={item.text}
              >
                <PictureAsPdfIcon fontSize="small" className={classes.downloadsectionicons} />
                {item.text}
              </Link>
            </li>
          ))}
        </ul>
      </Paper>
      <div className="grid-camping-camp-sam-hatcher">
        <Typography variant="h4" className={classes.bsah4}>
          Camp Sam Hatcher
        </Typography>
        <hr className={classes.hrmargin}></hr>
        <Typography variant="body1">
          <Link
            variant="body2"
            href="https://www.google.com/maps/place/Camp+Sam+Hatcher/@34.7422302,-76.9125259,15z/data=!4m5!3m4!1s0x0:0x4b95983033a86b35!8m2!3d34.7422302!4d-76.9125259"
            target="_blank"
            rel="noopener"
            title="Get directions - Camp Sam Hatcher"
          >
            <OpenInNewSharpIcon fontSize="inherit" className={classes.icons} />
            Located in Newport, North Carolina
          </Link>
          , Camp Sam Hatcher offers great and affordable campsites for units looking to visit the
          Crystal Coast. Sitting on 35 acres, Camp Sam Hatcher is used for weekend outings by Cub
          Scout Packs, Scout Troops, Venturing Crews, and other non-Scouting groups. Although most
          campsites do have potable water, they are still considered primative. A small restroom and
          cold shower facility, large program pavilion, and lodge are available upon request.
        </Typography>
        <div className={classes.ammenitiesContainer}>
          <Typography variant="h5" className={classes.subheader}>
            Ammenities
          </Typography>
          <Typography variant="body1">
            Perhaps the best ammenity is the opportunity to listen to the wind blowing through the
            longleaf pines, but if you need more than that here's what you'll find:
            <ul>
              <li>
                <Typography variant="body1">
                  A New Pirate Ship! It took a Big Rock Sports and East Carolina Council partnership
                  to get her built, but she's now available to those who may not want to be on the
                  water, but really like pretending to be a pirate!
                </Typography>
              </li>
              <li>
                <Typography variant="body1">Potable water near most campsites.</Typography>
              </li>
              <li>
                <Typography variant="body1">
                  A small restroom and shower facility (upon request).
                </Typography>
              </li>
              <li>
                <Typography variant="body1">Howard Lodge.</Typography>
              </li>
              <li>
                <Typography variant="body1">Large pavilion.</Typography>
              </li>
              <li>
                <Typography variant="body1">Fire-rings at campsites.</Typography>
              </li>
              <li>
                <Typography variant="body1">Activity Field.</Typography>
              </li>
            </ul>
          </Typography>
        </div>
      </div>
      <div className="grid-camping-camp-boddie">
        <Typography variant="h4" className={classes.bsah4}>
          Camp Boddie
        </Typography>
        <hr className={classes.hrmargin}></hr>
        <Typography variant="body1">
          <Link
            variant="body2"
            href="https://www.google.com/maps/place/Camp+Boddie+%7C+BSA/@35.4372291,-76.9445571,15z/data=!4m5!3m4!1s0x0:0xd626019ce6a2af9c!8m2!3d35.4372291!4d-76.9445571"
            target="_blank"
            rel="noopener"
            title="Get directions - Camp Boddie"
          >
            <OpenInNewSharpIcon fontSize="inherit" className={classes.icons} />
            (formerly Camp Bonner) is located along the picturesque banks of the Pamlico River&nbsp;
          </Link>
          as part of the East Carolina Scout Reservation. The East Carolina Scout Reservation boasts
          880 acres of beautiful eastern North Carolina wilderness and is available for year round
          use. Camp Boddie offers all the traditional merit badges plus a lot more! Enjoy everything
          from Archaeology to Wilderness Survival.
        </Typography>
        <div className={classes.ammenitiesContainer}>
          <Typography variant="h5" className={classes.subheader}>
            Ammenities
          </Typography>
          <Typography variant="body1">List coming soon...</Typography>
        </div>
      </div>
      <div className="grid-camping-pamlico-sea-base">
        <Typography variant="h4" className={classes.bsah4}>
          Pamlico Sea Base
        </Typography>
        <hr className={classes.hrmargin}></hr>
        <Typography variant="body1">
          <Link
            variant="body2"
            href="https://www.google.com/maps/dir//34.7425667,-76.9125404/@34.7388732,-76.9151134,3017m/data=!3m1!1e3?hl=en"
            target="_blank"
            rel="noopener"
            title="Get directions - Pamlico Sea Base"
          >
            <OpenInNewSharpIcon fontSize="inherit" className={classes.icons} />
            Located on the Pamlico River south of Washington, NC.
          </Link>
          The Sea Base program is centered around sea kayaking treks on the North Carolina Outer
          Banks and sailing programs on the historic Pamlico River. The Sea Base will also arrange
          scuba training/certification though accredited local instructors for interested
          participants. Email macompto@bsamail.org for more information
        </Typography>
        <div className={classes.ammenitiesContainer}>
          <Typography variant="h5" className={classes.subheader}>
            Ammenities
          </Typography>
          <Typography variant="body1">List coming soon...</Typography>
        </div>
      </div>
      <div className="grid-camping-potashcorp-eco-lodge">
        <Typography variant="h4" className={classes.bsah4}>
          Potashcorp Eco Lodge
        </Typography>
        <hr className={classes.hrmargin}></hr>
        <Typography variant="body1">
          <Link
            variant="body2"
            href="https://www.google.com/maps/place/Camp+Boddie+%7C+BSA/@35.4372291,-76.9445571,15z/data=!4m5!3m4!1s0x0:0xd626019ce6a2af9c!8m2!3d35.4372291!4d-76.9445571"
            target="_blank"
            rel="noopener"
            title="Get directions - Located near Blounts Creek, NC"
          >
            <OpenInNewSharpIcon fontSize="inherit" className={classes.icons} />
            The ECO Lodge is an environmental education center on the south side of the Pamlico
            River near Blounts Creek, NC;&nbsp;
          </Link>
          located within the East Carolina Scout Reservation. Programs provided by the Boy Scouts
          for Scouts, school, community groups and the citizens of eastern North Carolina. The ECO
          Lodge was made possible by a generous grant from PotashCorp of Aurora.
        </Typography>
        <div className={classes.ammenitiesContainer}>
          <Typography variant="h5" className={classes.subheader}>
            Ammenities
          </Typography>
          <Typography variant="body1">List coming soon...</Typography>
        </div>
      </div>
    </div>
  );
}
