import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";

import Datagrid from "./datagrid.component";
import Contactinfo from "./contactinfo.component";

import ContactList from "./contact_lists/District Contact Lists.json";

import PageBanner from "../../assets/images/find_a_unit_banner.png";

import "./styles.grid.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  bsah4: {
    fontFamily: "Marcellus",
    color: "#003f87",
  },
  subheader: {
    fontFamily: "Marcellus",
    color: "#CE1126",
  },
  hrmargin: {
    marginTop: "0px",
  },
  banner: {
    width: "100%",
  },
  gridScrolSpan: {
    color: "#a9a9a9",
  },
}));

export default function FindaunitPage() {
  const classes = useStyles();

  const [cubScoutData, setCubScoutData] = useState([]);
  const [ScoutData, setScoutData] = useState([]);
  const [seaScoutData, setSeaScoutData] = useState([]);
  const [ventureScoutData, setVentureScoutData] = useState([]);
  const [explorerPostsData, setExplorerPostsData] = useState([]);
  const [clubsData, setClubsData] = useState([]);
  const [contactInfo, setContactInfo] = useState({});
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    setCubScoutData(Object.keys(ContactList["Cub Scouts"]).map( key => ContactList["Cub Scouts"][key]));
    setScoutData(Object.keys(ContactList["Scouts"]).map( key => ContactList["Scouts"][key]));
    setSeaScoutData(Object.keys(ContactList["Sea Scouts"]).map( key => ContactList["Sea Scouts"][key]));
    setVentureScoutData(Object.keys(ContactList["Venture Crews"]).map( key => ContactList["Venture Crews"][key]));
    setExplorerPostsData(Object.keys(ContactList["Explorer Posts"]).map( key => ContactList["Explorer Posts"][key]));
    setClubsData(Object.keys(ContactList["Clubs"]).map( key => ContactList["Clubs"][key]));
  }, []);

  return (
    <div className="grid-findaunit-container">
      <Contactinfo
        contactInfo={contactInfo}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
      />
      <div className="grid-findaunit-header-image">
        <img className={classes.banner} src={PageBanner} alt="" />
      </div>
      <div className="grid-join-today">
        <Typography variant="h4" className={classes.bsah4}>
          Join Scouting Today!
        </Typography>
        <hr className={classes.hrmargin} />
        <Typography variant="body1" className={classes.subheader}>
          Here's how:
        </Typography>
        <ul>
          <li>
            <Typography>
              You may contact a Cub Scout Pack or Boy Scout Troop directly using the information
              provided here.
            </Typography>
          </li>
          <li>
            <Typography>
              Use&nbsp;
              <Link
                href="https://beascout.scouting.org/"
                target="_blank"
                rel="noopener"
                title="BSA's website"
              >
                BSA's website
              </Link>
              &nbsp;to guide you through the process.
            </Typography>
          </li>
        </ul>
      </div>
      <div className="data-grid-1">
        <Typography id="hideSpan" className={classes.gridScrolSpan} variant="caption">
          ** Scroll Grid left/right for more information
        </Typography>
        <Datagrid
          tableHeader="Cub Scout Pack Info"
          rows={cubScoutData}
          setContactInfo={setContactInfo}
          setOpenDialog={setOpenDialog}
        />
      </div>
      <div className="data-grid-2">
        <Typography id="hideSpan" className={classes.gridScrolSpan} variant="caption">
          ** Scroll Grid left/right for more information
        </Typography>
        <Datagrid
          tableHeader="Scouting Troop Info"
          rows={ScoutData}
          setContactInfo={setContactInfo}
          setOpenDialog={setOpenDialog}
        />
      </div>
      <div className="data-grid-3">
        <Typography id="hideSpan" className={classes.gridScrolSpan} variant="caption">
          ** Scroll Grid left/right for more information
        </Typography>
        <Datagrid
          tableHeader="Sea Scout Info"
          rows={seaScoutData}
          setContactInfo={setContactInfo}
          setOpenDialog={setOpenDialog}
        />
      </div>
      <div className="data-grid-4">
        <Typography id="hideSpan" className={classes.gridScrolSpan} variant="caption">
          ** Scroll Grid left/right for more information
        </Typography>
        <Datagrid
          tableHeader="Clubs Info"
          rows={clubsData}
          setContactInfo={setContactInfo}
          setOpenDialog={setOpenDialog}
        />
      </div>
      <div className="data-grid-5">
        <Typography id="hideSpan" className={classes.gridScrolSpan} variant="caption">
          ** Scroll Grid left/right for more information
        </Typography>
        <Datagrid
          tableHeader="Venture Crew Info"
          rows={ventureScoutData}
          setContactInfo={setContactInfo}
          setOpenDialog={setOpenDialog}
        />
      </div>
      <div className="data-grid-6">
        <Typography id="hideSpan" className={classes.gridScrolSpan} variant="caption">
          ** Scroll Grid left/right for more information
        </Typography>
        <Datagrid
          tableHeader="Explorer Posts Info"
          rows={explorerPostsData}
          setContactInfo={setContactInfo}
          setOpenDialog={setOpenDialog}
        />
      </div>
    </div>
  );
}
